import fetch from "isomorphic-fetch"
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth"
import { getAuthInstance } from "../hooks/karma-firebase"

const getCurrentUser = async (): Promise<FirebaseUser | null> => {
  const authInstance = await getAuthInstance()
  if (!authInstance) {
    return null
  }
  return new Promise((resolve) => {
    if (typeof window !== "undefined") {
      onAuthStateChanged(authInstance, function (user) {
        if (user) {
          resolve(user)
        } else {
          resolve(null)
        }
      })
    } else {
      resolve(null)
    }
  })
}

const uploadLink = createUploadLink({
  fetch,
  uri: ({ operationName }) => `${process.env.GATSBY_API_HTTP_ENDPOINT}/${operationName}`,
})
const authLink = setContext(async (_, { headers }) => {
  const user = await getCurrentUser()
  const token = user ? await user.getIdToken() : ""
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Karmeleon: {
      // Karmeleon doesn't comply with the normal "id" field, so we need to tell Apollo how to find it
      keyFields: ["karmeleon_id"],
    },
    PosIntegration: {
      keyFields: ["posIntegrationLocationId"],
    },
  },
})

export const client = new ApolloClient({
  cache,
  link: authLink.concat(uploadLink),
})
