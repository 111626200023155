import React, { useEffect } from "react"
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  setPersistence,
  signInWithPopup,
} from "firebase/auth"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { FirebaseApp, initializeApp } from "firebase/app"
import { navigate } from "gatsby"
import { User } from "@firebase/auth"

const isBrowser = typeof window !== "undefined"
const provider = new GoogleAuthProvider()

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
}

let instance: FirebaseApp | null = null

const getFirebase = () => {
  if (!isBrowser) {
    return undefined
  }
  if (instance) {
    return instance
  }
  instance = initializeApp(config)
  return instance
}

export const getAuthInstance = () => {
  if (!isBrowser) {
    return undefined
  }
  const firebaseInstance = getFirebase()
  const auth = getAuth(firebaseInstance)
  setPersistence(auth, browserLocalPersistence)
  return auth
}

export const useAuth = () => {
  const [loading, setLoading] = React.useState(true)
  const auth = getAuthInstance()
  const [user, setUser] = React.useState<User | null | undefined>(auth?.currentUser)

  const signInWithGoogle = async () => {
    try {
      if (!auth) {
        throw new Error("Auth instance not found")
      }
      const result = await signInWithPopup(auth, provider)
      const credential = GoogleAuthProvider.credentialFromResult(result)
      if (!credential) {
        throw new Error("Credential not found")
      }
      const user = result.user
      await handleKarmeleonCheck(user.email ?? "")
      setLoading(false)
      setUser(user)
      await navigate("/")
    } catch (error) {
      console.error(error)
    }
  }
  const signOut = async () => {
    try {
      await auth?.signOut()
      setUser(null)
      await navigate("/login")
    } catch (error) {
      console.error(error)
    }
  }
  const isKarmeleon = (userEmail?: string | null) => {
    return userEmail?.endsWith("@karma.life")
  }
  const handleKarmeleonCheck = async (userEmail?: string) => {
    if (!isKarmeleon(userEmail)) {
      await signOut()
      await navigate("/login")
    }
  }
  useEffect(() => {
    setLoading(true)
    if (auth) {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          await handleKarmeleonCheck(user.email ?? "")
        }
        setUser(user)
      })
    }
    setLoading(false)
  }, [auth, user])

  return {
    isLoading: loading,
    isLoggedIn: isKarmeleon(user?.email),
    signInWithGoogle,
    signOut,
    user,
  }
}

// This is only exported because some legacy class components cant use hooks
export const getStorageRef = (refName: string) => {
  const firebaseInstance = getFirebase()
  const storage = getStorage(firebaseInstance)

  return ref(storage, refName)
}

export const uploadFile = async (file: File, refName: string) => {
  const storageRef = getStorageRef(refName)
  const snapshot = await uploadBytes(storageRef, file)
  const url = await getDownloadURL(snapshot.ref)
  return url
}

export const useStorage = () => {
  // This hook is used for firebase storage
  const firebaseInstance = getFirebase()
  const storage = getStorage(firebaseInstance)
  return {
    storage,
  }
}
